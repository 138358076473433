<template>
  <div class="main-content flex-1 bg-gray-100">
    <loading-overlay :loading="loading_overlay" />
    <div class="p-2 md:p-6">
      <div class="flex justify-between">
        <div>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-col text-center overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th>
              <div class="inline-block">{{ $t("transactions.id") }}</div>
            </th>
            <th>
              <div>{{ $t("transactions.username") }}</div>
              <select
                v-if="hasPrivilege('accounting_search_transactions')"
                @change="applyFilters"
                v-model="filters.user_id"
                class="
                  bg-white
                  rounded-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  text-center
                  w-2/3
                "
              >
                <option value="-1">{{ $t("control.all") }}</option>
                <option
                  v-for="ft in admins"
                  v-bind:key="ft.id"
                  :value="ft.id"
                  :class="{
                    'text-green-400 text-center': ft.type == 'reseller',
                  }"
                >
                  {{ ft.email }}
                </option>
              </select>
            </th>
            <th>
              <div>{{ $t("transactions.account_username") }}</div>
              <input
                v-if="hasPrivilege('accounting_search_transactions')"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-2/3
                  shadow-lg
                  p-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.account_username"
              />
            </th>
            <th>
              <div>{{ $t("transactions.type") }}</div>
              <select
                v-if="hasPrivilege('accounting_search_transactions')"
                @change="applyFilters"
                v-model="filters.type"
                class="
                  bg-white
                  rounded-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  text-center
                  w-2/3
                "
              >
                <option value="">{{ $t("control.all") }}</option>
                <option
                  v-for="ft in ['deposit', 'withdraw']"
                  v-bind:key="ft"
                  :value="ft"
                >
                  {{ $t(`transactions.${ft}`) }}
                </option>
              </select>
            </th>
            <th>
              <div>{{ $t("transactions.amount") }}</div>
            </th>
            <th>
              <div>{{ $t("admins_log.operation") }}</div>
              <select
                v-if="hasPrivilege('accounting_search_transactions')"
                @change="applyFilters"
                v-model="filters.subject"
                class="
                  bg-white
                  rounded-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  text-center
                  w-2/3
                "
              >
                <option value="">{{ $t("control.all") }}</option>
                <option v-for="ft in operations" v-bind:key="ft" :value="ft">
                  {{ $t(`operations.${ft}`) }}
                </option>
              </select>
            </th>
            <th>
              <div>{{ $t("transactions.notes") }}</div>
              <input
                v-if="hasPrivilege('accounting_search_transactions')"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-2/3
                  shadow-lg
                  p-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.notes"
              />
            </th>
            <th>
              <div>{{ $t("control.created_at") }}</div>
              <input
                v-if="hasPrivilege('accounting_search_transactions')"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-2/3
                  shadow-lg
                  p-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.created_at"
              />
            </th>
            <th>
              <div>{{ $t("control.updated_at") }}</div>
              <input
                v-if="hasPrivilege('accounting_search_transactions')"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-2/3
                  shadow-lg
                  p-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.updated_at"
              />
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, index) in dataList"
              v-bind:key="transaction.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
              }"
            >
              <td>{{ transaction.id }}</td>
              <td>{{ transaction.user.email }}</td>
              <td>
                <div
                  v-if="transaction.account"
                  class="text-blue-700 cursor-pointer border-b border-blue-700 font-bold inline-block"
                  @click.prevent="
                    this.$store.commit('changeKey');
                    this.$router.push(
                      `/accounts?useranme=${transaction.account.username}`
                    );
                  "
                >
                  {{ transaction.account.username }}
                </div>
                <div v-else>DELETED</div>
              </td>
              <td>
                <div
                  v-if="transaction.type == 'withdraw'"
                  class=" rounded-full px-2 py-1 bg-red-600 text-red-200"
                >
                  {{ $t(`transactions.${transaction.type}`) }}
                </div>
                <div
                  v-else
                  class=" rounded-full px-2 py-1 bg-green-600 text-green-200"
                >
                  {{ $t(`transactions.${transaction.type}`) }}
                </div>
              </td>
              <td class="text-xl">
                {{ transaction.amount
                }}<span class=" font-sans font-bold">{{
                  this.$store.getters.settings.currency
                }}</span>
              </td>
              <td>{{ $t(`operations.${transaction.subject}`) }}</td>
              <td>{{ transaction.notes }}</td>
              <td>{{ formatDate(transaction.created_at) }}</td>
              <td>{{ formatDate(transaction.updated_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class=" inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>
<script>
import PaginationRow from "../components/PaginationRow.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import axios from "axios";
import moment from "moment";
import { hasPrivilege } from "../global_constants.js";
import { mapGetters } from "vuex";
export default {
  name: "Transactions",
  components: { PaginationRow, LoadingOverlay },
  data() {
    return {
      dataList: null,
      admins: null,
      meta: { last_page: 0 },
      loading: true,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        id: "",
        user_id: -1,
        account_username: "",
        type: "",
        amount: "",
        subject: "",
        notes: "",
        created_at: "",
        updated_at: "",
      },
      autoUpdate: false,
      selection_array: [],
      debounce: null,
      loading_overlay: false,
      operations: ["account_renew", "bill_pay", "reseller_benefit"],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  async created() {
    this.applyFilters();
    this.fetchAdmins();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async applyFilters() {
      this.loading = true;
      var requestString = "transactions?filters=1";
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.user_id != -1)
        requestString += `&user_id=${this.filters.user_id}`;
      if (this.filters.account_username != "")
        requestString += `&account_username=${this.filters.account_username}`;
      if (this.filters.type != "")
        requestString += `&type=${this.filters.type}`;
      if (this.filters.amount != "")
        requestString += `&amount=${this.filters.amount}`;
      if (this.filters.subject != "")
        requestString += `&subject=${this.filters.subject}`;
      if (this.filters.notes != "")
        requestString += `&notes=${this.filters.notes}`;
      if (this.filters.created_at != "")
        requestString += `&created_at=${this.filters.created_at}`;
      if (this.filters.updated_at != "")
        requestString += `&updated_at=${this.filters.updated_at}`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = response.data.data;
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        if (this.sort_col) {
          if (this.sort_dir == "ASC") {
            this.dataList.sort((a, b) =>
              a[this.sort_col] > b[this.sort_col]
                ? 1
                : b[this.sort_col] > a[this.sort_col]
                ? -1
                : 0
            );
          } else {
            this.dataList.sort((a, b) =>
              a[this.sort_col] < b[this.sort_col]
                ? 1
                : b[this.sort_col] < a[this.sort_col]
                ? -1
                : 0
            );
          }
        }
        this.meta = response.data.meta;
        this.loading = false;
      }, 1000);
    },
    async fetchAdmins() {
      const response = await axios.get("adminslog/admins");
      if (response.status == 200) {
        this.admins = response.data.data;
      }
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    formatDate(date) {
      return moment(date).format("yyyy-MM-DD hh:mm a");
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
  },
};
</script>
