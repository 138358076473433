<template>
  <div
    @click.prevent="if (this.$refs.menu != null) this.$refs.menu.close();"
    class="main-content flex-1 bg-gray-100"
  >
    <loading-overlay :loading="loading_overlay" />
    <transition name="fade">
      <profile-details
        :visible="showProfileDetails"
        :selectedProfile="selectedData.profile"
        @hide-details="showProfileDetails = false"
      />
    </transition>
    <transition name="fade">
      <account-details
        :visible="showDetails"
        :selectedAccount="selectedData"
        @updated="
          showDetails = false;
          applyFilters();
        "
        @renew="
          showDetails = false;
          showRenew = true;
        "
        @money="
          showDetails = false;
          showMoney = true;
        "
        @temp_renew="
          showDetails = false;
          renewTemp(selectedData);
        "
        @temp_speed="
          showDetails = false;
          speedTemp(selectedData);
        "
        @delete="
          showDetails = false;
          showDelete = true;
        "
        @offline="
          showDetails = false;
          this.$router.push({
            path: '/accounts',
            query: { status: 'already_offline' },
          });
        "
        @failed="
          showDetails = false;
          this.$router.push({
            path: '/accounts',
            query: { status: 'fail' },
          });
        "
        @hide-details="showDetails = false"
      />
    </transition>
    <transition name="fade">
      <renew-profile
        v-if="showRenew && hasPrivilege('accounts_edit_accounts')"
        :visible="showRenew"
        :selectedAccount="selectedData"
        @hide-details="showRenew = false"
        @updated="
          applyFilters();
          showRenew = false;
        "
      />
    </transition>
    <transition name="fade">
      <TempSpeed
        v-if="showTempSpeed && hasPrivilege('accounts_temp_speed_accounts')"
        :visible="showTempSpeed"
        :selectedAccount="selectedData"
        @hide-details="showTempSpeed = false"
        @updated="
          applyFilters();
          showTempSpeed = false;
        "
      />
    </transition>
    <transition name="fade">
      <account-money
        v-if="showMoney"
        :visible="showMoney"
        :selectedAccount="selectedData"
        @hide-details="showMoney = false"
        @updated="
          applyFilters();
          showMoney = false;
        "
      />
    </transition>
    <transition name="fade">
      <EditAccountsExpiration
        v-if="showEditExpirationAll"
        :visible="showEditExpirationAll"
        :selectedAccount="selection_array"
        @hide-details="showEditExpirationAll = false"
        @updated="
          applyFilters();
          showEditExpirationAll = false;
          selection_array = [];
        "
      />
    </transition>
    <transition name="fade">
      <delete-confirm
        :visible="showDelete"
        @hide="showDelete = false"
        @deleteconfirmed="deleteData"
      />
    </transition>
    <transition name="fade">
      <columns-select
        :visible="showColumnsSelect"
        :columns="columns"
        :name="'accounts_columns'"
        @hide="showColumnsSelect = false"
        @selectconfirmed="updateColumns(currentColumns)"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.accounts.delete_all')"
        :visible="showDeleteAll"
        @confirmed="groupDelete()"
        @hide="showDeleteAll = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.accounts.clear_all')"
        :visible="showClearAll"
        @confirmed="groupClear()"
        @hide="showClearAll = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.accounts.disable')"
        :visible="showDisable"
        @confirmed="disableData()"
        @hide="showDisable = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.accounts.enable')"
        :visible="showEnable"
        @confirmed="disableData()"
        @hide="showEnable = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.accounts.disconnect')"
        :visible="showDisconnect"
        @confirmed="disconnectData()"
        @hide="showDisconnect = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.accounts.clear')"
        :visible="showClear"
        @confirmed="clearData()"
        @hide="showClear = false"
      />
    </transition>
    <transition name="fade">
      <push-account-notification
        :visible="showNotification"
        :account="selectedData"
        @updated="showNotification = false"
        @hide="showNotification = false"
      />
    </transition>

    <transition name="expandy">
      <accounts-context :display="showContext" ref="menu">
        <ul
          v-if="selectedData"
          :class="{
            disabled: !this.$store.getters.settings.license.active,
          }"
        >
          <li
            @click="showDetails = true"
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            {{ $t("actions.view") }}
          </li>
          <li
            v-if="hasPrivilege('accounts_edit_accounts')"
            @click="editData(selectedData)"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg bg-blue-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
            {{ $t("actions.edit") }}
          </li>
          <li
            v-if="hasPrivilege('accounts_edit_accounts')"
            @click="editData(selectedData, 'new')"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg bg-blue-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            {{ $t("actions.edit_popup") }}
          </li>
          <li
            v-if="hasPrivilege('accounts_renew_48')"
            @click="renewTemp(selectedData)"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6 rounded-lg inline-block bg-indigo-400 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            {{ $t("actions.renew_48") }}
          </li>
          <li
            v-if="hasPrivilege('accounts_edit_accounts')"
            @click="showRenew = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 rounded-lg inline-block bg-indigo-400 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {{ $t("actions.renew") }}
          </li>
          <li
            v-if="hasPrivilege('accounting_view_account_bills')"
            @click="
              this.$router.push(`/bills?username=${selectedData.username}`)
            "
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-green-600 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            {{ $t("actions.view_bills") }}
          </li>
          <li
            v-if="
              hasPrivilege('notifications_edit_manual_notifications') ||
                hasPrivilege('notifications_push_notifications')
            "
            @click="showNotification = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 rounded-lg inline-block bg-yellow-400 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            {{ $t("actions.notify") }}
          </li>
          <li
            @click="
              this.$store.commit('changeKey');
              this.$router.push(`/sessions/${selectedData.username}`);
            "
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-green-600 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            {{ $t("actions.view_sessions") }}
          </li>
          <li
            v-if="hasPrivilege('accounts_temp_speed_accounts')"
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="showTempSpeed = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="h-6 w-6 inline-block bg-green-600 text-white rounded-lg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
              />
            </svg>

            {{ $t("actions.temp_speed") }}
          </li>
          <li
            v-if="
              hasPrivilege('accounts_disconnect_accounts') &&
                this.user.data.type == 'reseller'
            "
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="showClear = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-green-400 rounded-lg text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {{ $t("actions.clear") }}
          </li>
          <li
            v-if="
              hasPrivilege('accounts_disconnect_accounts') &&
                this.user.data.type == 'reseller'
            "
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="showDisconnect = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-400 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {{ $t("actions.disconnect") }}
          </li>
          <li
            v-if="
              hasPrivilege('accounts_disable_accounts') && !selectedData.enabled
            "
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="enableData()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-green-500 text-green-500 rounded-lg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
              />
            </svg>
            {{ $t("actions.enable") }}
          </li>
          <li
            v-if="
              hasPrivilege('accounts_disable_accounts') && selectedData.enabled
            "
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="showDisable = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-gray-400 text-white rounded-lg"
              viewBox="0 0 24 24"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
              />
            </svg>
            {{ $t("actions.disable") }}
          </li>
          <li
            v-if="hasPrivilege('accounts_delete_accounts')"
            @click="showDelete = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            {{ $t("actions.delete") }}
          </li>
        </ul>
      </accounts-context>
    </transition>
    <div class="p-2 md:p-6" @click.prevent="showFilters = false">
      <div class="flex justify-between">
        <div>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.save,
              'bg-gray-700 text-white': !filters.save,
            }"
            @click.prevent="toggleSaveFilters"
          >
            {{
              autoUpdate
                ? "✓ " + $t("control.save_filters")
                : $t("control.save_filters")
            }}
          </button>
        </div>
        <div class="flex">
          <button
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white px-4 mr-4"
            @click.prevent="exportCsv"
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            {{ $t("actions.export_csv") }}
          </button>
          <div>
            <button
              class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white py-1 px-4 font-bold mr-4"
              v-print="'#printContent'"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-9 w-9"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                />
              </svg>
            </button>
          </div>

          <button
            class="rounded-full bg-ssr-side_bar-settings text-xs lg:text-base text-white py-1 lg:py-2 px-4 font-bold mr-4"
            @click.prevent="showColumnsSelect = true"
          >
            ...
          </button>

          <button
            v-if="hasPrivilege('accounts_edit_accounts')"
            @click.prevent="this.$router.push('/accounts/create')"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white lg:p-2 px-2"
            :class="{ disabled: !this.$store.getters.settings.license.active }"
          >
            {{ $t("accounts.add_account") }}
          </button>
        </div>
      </div>
      <Vue3SimpleHtml2pdf
        ref="vue3SimpleHtml2pdf"
        :options="pdfOptions"
        :filename="exportFilename"
        id="printContent"
      >
        <div class="w-full flex flex-col text-center overflow-auto scroll">
          <table>
            <thead class="border-b-2 border-black text-xs md:text-base">
              <th class="noPrint"></th>
              <th class="noPrint">
                <table-check-box
                  class="w-5 h-5 my-3 mx-4 cursor-pointer"
                  :value="allSelected"
                  @click="checkAll"
                />
              </th>
              <th class="align-bottom" v-if="columns.id.enabled">
                <div class="inline-block">{{ $t("accounts.id") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.id.sort }"
                  @click="sort('id')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  v-model="filters.id"
                  @input="applyFilters"
                />
              </th>
              <th class="align-bottom" v-if="columns.serial_number.enabled">
                <div class="inline-block">
                  {{ $t("accounts.serial_number") }}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.serial_number.sort }"
                  @click="sort('serial_number')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  v-model="filters.serial_number"
                  @input="applyFilters"
                />
              </th>
              <th class="align-bottom" v-if="columns.first_name.enabled">
                <div class="inline-block">{{ $t("accounts.first_name") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.first_name.sort }"
                  @click="sort('first_name')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  v-model="filters.first_name"
                  @input="applyFilters"
                />
              </th>
              <th class="align-bottom" v-if="columns.last_name.enabled">
                <div class="inline-block">{{ $t("accounts.last_name") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.last_name.sort }"
                  @click="sort('last_name')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  v-model="filters.last_name"
                  @input="applyFilters"
                />
              </th>
              <th class="align-bottom" v-if="columns.username.enabled">
                <div>
                  <div class="inline-block">{{ $t("accounts.username") }}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                    :class="{ 'rotate-180': columns.username.sort }"
                    @click="sort('username')"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 15l7-7 7 7"
                    />
                  </svg>
                </div>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  v-model="filters.username"
                  @input="applyFilters"
                />
              </th>
              <th class="align-bottom" v-if="columns.password.enabled">
                <div>
                  <div class="inline-block">{{ $t("accounts.password") }}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                    :class="{ 'rotate-180': columns.password.sort }"
                    @click="sort('password')"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 15l7-7 7 7"
                    />
                  </svg>
                </div>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  v-model="filters.password"
                  @input="applyFilters"
                />
              </th>
              <th class="align-bottom" v-if="columns.phone.enabled">
                <div class="inline-block">{{ $t("accounts.phone") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.phone.sort }"
                  @click="sort('phone')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  @input="applyFilters"
                  v-model="filters.phone"
                />
              </th>
              <th class="align-bottom" v-if="columns.phone_line.enabled">
                <div class="inline-block">{{ $t("accounts.phone_line") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.phone_line.sort }"
                  @click="sort('phone_line')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  @input="applyFilters"
                  v-model="filters.phone_line"
                />
              </th>
              <th class="align-bottom" v-if="columns.address.enabled">
                <div class="inline-block">
                  {{
                    this.$store.getters.settings.center
                      ? "رقم البوابة"
                      : $t("accounts.address")
                  }}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.address.sort }"
                  @click="sort('address')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  @input="applyFilters"
                  v-model="filters.address"
                />
              </th>
              <th class="align-bottom" v-if="columns.notes.enabled">
                <div class="inline-block">{{ $t("accounts.notes") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.notes.sort }"
                  @click="sort('notes')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  @input="applyFilters"
                  v-model="filters.notes"
                />
              </th>
              <th class="align-bottom" v-if="columns.profile.enabled">
                <div class="inline-block">{{ $t("accounts.profile") }}</div>
                <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.profile.sort }"
                @click="sort_profile()"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg> -->
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  @input="applyFilters"
                  v-model="filters.profile"
                />
              </th>
              <th class="align-bottom" v-if="columns.ip_address.enabled">
                <div class="inline-block">{{ $t("accounts.ip_address") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.ip_address.sort }"
                  @click="sort('ip_address')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  @input="applyFilters"
                  v-model="filters.ip_address"
                />
              </th>
              <th class="align-bottom" v-if="columns.expires_at.enabled">
                <div class="inline-block">{{ $t("accounts.expires_at") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.expires_at.sort }"
                  @click="sort('expires_at')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  placeholder="Ex: 2021-01-01"
                  @input="applyFilters"
                  v-model="filters.expires_at"
                />
              </th>
              <th class="align-bottom" v-if="columns.allowed_sessions.enabled">
                <div class="inline-block">{{ $t("accounts.sessions") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.allowed_sessions.sort }"
                  @click="sort('allowed_sessions')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.filter')"
                  @input="applyFilters"
                  v-model="filters.allowed_sessions"
                />
              </th>
              <th class="align-bottom" v-if="columns.auto_renew.enabled">
                <div class="inline-block">{{ $t("accounts.auto_renew") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.auto_renew.sort }"
                  @click="sort('auto_renew')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <select
                  class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none text-center w-full noPrint"
                  @change="applyFilters"
                  v-model="filters.auto_renew"
                >
                  <option value="">{{ $t("control.all") }}</option>
                  <option value="0">{{ $t("control.no") }}</option>
                  <option value="1">{{ $t("control.yes") }}</option>
                </select>
              </th>
              <th
                class="align-bottom"
                v-if="columns.remaining_quota_mb.enabled"
              >
                <div class="inline-block">{{ $t("accounts.used_quota") }}</div>
                <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.remaining_quota_mb.sort }"
                @click="sort('remaining_quota_mb')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg> -->
              </th>
              <th class="align-bottom" v-if="columns.active.enabled">
                <div class="inline-block">{{ $t("accounts.status") }}</div>
                <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.active.sort }"
                @click="sort('active')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg> -->
                <select
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  @change="applyFilters"
                  v-model="filters.active"
                  class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none text-center w-full noPrint"
                >
                  <option
                    v-for="ft in [
                      { id: '', name: $t('control.all') },
                      { id: 'active', name: $t('control.active') },
                      { id: 'expired', name: $t('control.expired') },
                      { id: 'expiring', name: $t('control.expiring') },
                      {
                        id: 'expiration_profile',
                        name: $t('profiles.expiration_profile'),
                      },
                      { id: 'disabled', name: $t('control.disabled') },
                    ]"
                    v-bind:key="ft.id"
                    :value="ft.id"
                  >
                    {{ ft.name }}
                  </option>
                </select>
              </th>
              <th class="align-bottom" v-if="columns.online.enabled">
                <div class="inline-block">{{ $t("accounts.connection") }}</div>
                <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.online.sort }"
                @click="sort('online')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg> -->
                <select
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  @change="applyFilters"
                  v-model="filters.online"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 pl-3 focus:outline-none text-center noPrint"
                >
                  <option
                    v-for="ft in [
                      { id: '', name: $t('control.all') },
                      { id: 'online', name: $t('control.online') },
                      { id: 'offline', name: $t('control.offline') },
                    ]"
                    v-bind:key="ft.id"
                    :value="ft.id"
                  >
                    {{ ft.name }}
                  </option>
                </select>
              </th>
              <th
                class="align-bottom"
                v-if="
                  columns.debt.enabled &&
                    hasPrivilege('accounting_view_account_debts')
                "
              >
                <div class="inline-block">{{ $t("accounts.debts") }}</div>
                <svg
                  v-if="hasPrivilege('accounting_view_account_debts')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.debt.sort }"
                  @click="sort('debt')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              </th>
              <th class="align-bottom" v-if="columns.mac_address.enabled">
                <div class="inline-block">{{ $t("accounts.mac_address") }}</div>
              </th>
              <th class="align-bottom" v-if="columns.last_seen.enabled">
                <div class="inline-block">{{ $t("accounts.last_seen") }}</div>
                <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-6
                  w-6
                  inline-block
                  transform
                  transition-all
                  cursor-pointer
                "
                :class="{ 'rotate-180': columns.last_seen.sort }"
                @click="sort('last_seen')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg> -->
              </th>
              <th class="align-bottom" v-if="columns.paid_at.enabled">
                <div class="inline-block">{{ $t("accounts.paid_at") }}</div>
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.paid_at.sort }"
                  @click="sort('paid_at')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('accounts.paid_at')"
                  @input="applyFilters"
                  v-model="filters.paid_at"
                /> -->
              </th>
              <th class="align-bottom" v-if="columns.created_at.enabled">
                <div class="inline-block">{{ $t("control.created_at") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer noPrint"
                  :class="{ 'rotate-180': columns.created_at.sort }"
                  @click="sort('created_at')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <input
                  v-if="hasPrivilege('accounts_view_search_accounts')"
                  type="text"
                  class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center noPrint"
                  :placeholder="$t('control.created_at')"
                  @input="applyFilters"
                  v-model="filters.created_at"
                />
              </th>
            </thead>
            <tbody class="overflow-y-scroll h-60">
              <tr
                v-for="(account, index) in dataList"
                v-bind:key="account.id"
                class="text-xs md:text-base"
                :class="{
                  'bg-gray-200': index % 2 == 0,
                  'bg-blue-300 hover:bg-blue-300': account.selected,
                }"
                @contextmenu.prevent="openContextMenu($event, account)"
              >
                <td class="font-bold noPrint">
                  {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
                </td>
                <td class="noPrint">
                  <table-check-box
                    class="w-5 h-5 my-3 mx-4 cursor-pointer"
                    :value="account.selected"
                    @click.prevent="
                      selectData(account);
                      showDetails = false;
                    "
                  />
                </td>
                <td v-if="columns.id.enabled">
                  {{ account.id }}
                </td>
                <td v-if="columns.serial_number.enabled">
                  {{ account.serial_number }}
                </td>
                <td v-if="columns.first_name.enabled">
                  {{ account.first_name }}
                </td>
                <td v-if="columns.last_name.enabled">
                  {{ account.last_name }}
                </td>
                <td v-if="columns.username.enabled">
                  <a
                    class="cursor-pointer underline text-blue-800"
                    @click.prevent="
                      selectedData = account;
                      showDetails = true;
                    "
                    :class="{
                      disabled: !this.$store.getters.settings.license.active,
                    }"
                  >
                    {{ account.username }}
                  </a>
                </td>
                <td v-if="columns.password.enabled">{{ account.password }}</td>
                <td v-if="columns.phone.enabled">{{ account.phone }}</td>
                <td v-if="columns.phone_line.enabled">
                  {{ account.phone_line }}
                </td>
                <td v-if="columns.address.enabled">{{ account.address }}</td>
                <td v-if="columns.notes.enabled">{{ account.notes }}</td>
                <td v-if="columns.profile.enabled">
                  <a
                    v-if="user.data.type != 'reseller'"
                    class="cursor-pointer underline text-blue-800"
                    @click.prevent="editProfile(account)"
                    :href="`/profiles/${account.profile.id}/edit`"
                    :class="{
                      disabled: !this.$store.getters.settings.license.active,
                    }"
                  >
                    {{ account.profile.name }}
                  </a>
                  <div v-if="user.data.type == 'reseller'">
                    {{ account.profile.name }}
                  </div>
                </td>
                <td v-if="columns.ip_address.enabled">
                  {{
                    account.ip_address ? account.ip_address : $t("control.auto")
                  }}
                </td>
                <td v-if="columns.expires_at.enabled">
                  {{ this.formatDate(account.expires_at) }}
                </td>

                <td v-if="columns.allowed_sessions.enabled">
                  {{ account.allowed_sessions }}
                </td>
                <td v-if="columns.auto_renew.enabled">
                  {{
                    account.auto_renew ? $t("control.yes") : $t("control.no")
                  }}
                </td>
                <td v-if="columns.remaining_quota_mb.enabled">
                  <div
                    v-if="account.profile.traffic_limited"
                    class="flex justify-center relative"
                  >
                    <div
                      v-if="hasPrivilege('accounts_view_accounts_quota')"
                      class="w-12 h-12 relative flex justify-center items-center"
                    >
                      <pie-chart
                        :value="
                          parseFloat(
                            (account.used_quota_mb * 100) /
                              account.allowed_quota_mb
                          ).toFixed(0)
                        "
                        :color="percentColor(account)"
                      />
                      <div class="absolute font-bold">
                        {{
                          parseFloat(
                            (account.used_quota_mb * 100) /
                              account.allowed_quota_mb
                          ).toFixed(0) + "%"
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-if="!account.profile.traffic_limited">
                    {{ $t("control.unlimited") }}
                  </div>
                </td>
                <td v-if="columns.active.enabled">
                  <div
                    v-if="hasPrivilege('accounts_view_accounts_status')"
                    class="cursor-pointer"
                    :class="{
                      disabled: !this.$store.getters.settings.license.active,
                    }"
                  >
                    <div
                      v-if="!account.enabled"
                      class="px-3 p-1 bg-red-600 text-white text-center text-xs rounded-full inline-block"
                      @click="
                        selectedData = account;
                        showEnable = true;
                      "
                    >
                      {{ $t("control.disabled") }}
                    </div>
                    <div
                      v-else-if="account.active && account.enabled"
                      class="px-3 p-1 bg-blue-600 text-white text-center text-xs rounded-full inline-block"
                      @click="
                        selectedData = account;
                        showRenew = true;
                      "
                    >
                      {{ $t("control.active") }}
                    </div>
                    <div
                      v-else-if="
                        !account.active &&
                          account.enabled &&
                          !account.profile.has_expiration_profile
                      "
                      class="px-3 p-1 bg-yellow-600 text-white text-center text-xs rounded-full inline-block"
                      @click="
                        selectedData = account;
                        showRenew = true;
                      "
                    >
                      {{ $t("control.expired") }}
                    </div>
                    <div
                      v-else-if="
                        !account.active &&
                          account.enabled &&
                          account.profile.has_expiration_profile
                      "
                      class="px-3 p-1 bg-yellow-800 text-white text-center text-xs rounded-full inline-block"
                      @click="
                        selectedData = account;
                        showRenew = true;
                      "
                    >
                      {{ $t("profiles.expiration_profile") }}
                    </div>
                  </div>
                </td>
                <td v-if="columns.online.enabled">
                  <div v-if="hasPrivilege('accounts_view_accounts_status')">
                    <div
                      v-if="account.online"
                      class="px-3 p-1 bg-green-600 text-white text-center text-xs rounded-full inline-block"
                    >
                      {{ $t("control.online") }}
                    </div>
                    <div
                      v-if="!account.online"
                      class="px-3 p-1 bg-gray-800 text-white text-center text-xs rounded-full inline-block"
                    >
                      {{ $t("control.offline") }}
                    </div>
                  </div>
                </td>
                <td
                  v-if="
                    columns.debt.enabled &&
                      hasPrivilege('accounting_view_account_debts')
                  "
                >
                  <div class="text-xl">
                    {{ account.debt }}
                    <span class="font-sans font-bold">{{
                      this.$store.getters.settings.currency
                    }}</span>
                  </div>
                </td>
                <td v-if="columns.mac_address.enabled">
                  <div v-if="hasPrivilege('accounts_view_accounts_status')">
                    {{ account.mac_address }}
                  </div>
                </td>
                <td v-if="columns.last_seen.enabled">
                  <div v-if="hasPrivilege('accounts_view_accounts_status')">
                    {{ formatDateHours(account.last_seen) }}
                  </div>
                </td>
                <td v-if="columns.paid_at.enabled">
                  <div>
                    {{ formatDateHours(account.paid_at) }}
                  </div>
                </td>
                <td v-if="columns.created_at.enabled">
                  <div>
                    {{ formatDateHours(account.created_at) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="flex justify-start">
          <button
            class=" bg-blue-300 rounded-lg text-black p-1"
            @click.prevent="editAccount(account)"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1667 2.5C14.3856 2.28113 14.6455 2.10752 14.9314 1.98906C15.2174 1.87061 15.5239 1.80965 15.8334 1.80965C16.1429 1.80965 16.4494 1.87061 16.7354 1.98906C17.0214 2.10752 17.2812 2.28113 17.5001 2.5C17.719 2.71887 17.8926 2.97871 18.011 3.26468C18.1295 3.55064 18.1904 3.85714 18.1904 4.16667C18.1904 4.4762 18.1295 4.7827 18.011 5.06866C17.8926 5.35463 17.719 5.61447 17.5001 5.83334L6.25008 17.0833L1.66675 18.3333L2.91675 13.75L14.1667 2.5Z"
                stroke="#262938"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            @click.prevent="
              showDetails = true;
              selectedAccount = account;
            "
            class=" bg-yellow-300 rounded-lg text-black p-1"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M0.833252 9.99999C0.833252 9.99999 4.16658 3.33333 9.99992 3.33333C15.8333 3.33333 19.1666 9.99999 19.1666 9.99999C19.1666 9.99999 15.8333 16.6667 9.99992 16.6667C4.16658 16.6667 0.833252 9.99999 0.833252 9.99999Z"
                  stroke="#262937"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                  stroke="#262937"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button
            @click.prevent="
              showDelete = true;
              selectedAccount = account;
            "
            class=" bg-red-400 rounded-lg text-black p-1"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 5H4.16667H17.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.66675 5.00001V3.33334C6.66675 2.89131 6.84234 2.46739 7.1549 2.15483C7.46746 1.84227 7.89139 1.66667 8.33342 1.66667H11.6667C12.1088 1.66667 12.5327 1.84227 12.8453 2.15483C13.1578 2.46739 13.3334 2.89131 13.3334 3.33334V5.00001M15.8334 5.00001V16.6667C15.8334 17.1087 15.6578 17.5326 15.3453 17.8452C15.0327 18.1577 14.6088 18.3333 14.1667 18.3333H5.83341C5.39139 18.3333 4.96746 18.1577 4.6549 17.8452C4.34234 17.5326 4.16675 17.1087 4.16675 16.6667V5.00001H15.8334Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.33325 9.16667V14.1667"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.6667 9.16667V14.1667"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div> -->
        </div>
      </Vue3SimpleHtml2pdf>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class="inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length == 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          @click="
            selectedData = selection_array[0];
            showDetails = true;
          "
          class="ml-2 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.view") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounts_edit_accounts')"
          @click="editData(selection_array[0])"
          class="mx-3 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-blue-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.edit") }}
          </div>
        </div>
        <div
          v-if="
            hasPrivilege('notifications_edit_manual_notifications') ||
              hasPrivilege('notifications_push_notifications')
          "
          @click="
            selectedData = selection_array[0];
            showNotification = true;
          "
          class="cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-yellow-300 mr-3 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.notify") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounts_renew_48')"
          @click="renewTemp(selection_array[0])"
          class="mr-2 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6 rounded-lg inline-block bg-indigo-400 text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.renew_48") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounts_edit_accounts')"
          @click="renewData(selection_array[0])"
          class="mr-2 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 rounded-lg inline-block bg-indigo-400 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.renew") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounting_view_account_bills')"
          @click="this.$router.push(`/bills?username=${selectedData.username}`)"
          class="cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-green-600 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.view_bills") }}
          </div>
        </div>
        <div
          @click="
            selectedData = selection_array[0];
            this.$router.push(`/sessions/${selectedData.username}`);
          "
          class="mx-2 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-green-600 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.view_sessions") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounts_temp_speed_accounts')"
          class="cursor-pointer group relative"
          @click="
            selectedData = selection_array[0];
            showTempSpeed = true;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-6 w-6 inline-block bg-green-600 text-white rounded-lg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
            />
          </svg>

          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.temp_speed") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounts_disconnect_accounts')"
          class="cursor-pointer group relative ml-3"
          @click="
            selectedData = selection_array[0];
            showClear = true;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-green-400 rounded-lg text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.clear") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounts_disconnect_accounts')"
          class="cursor-pointer group relative ml-3"
          @click="
            selectedData = selection_array[0];
            showDisconnect = true;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-400 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.disconnect") }}
          </div>
        </div>
        <div
          v-if="
            hasPrivilege('accounts_disable_accounts') &&
              selection_array[0].enabled
          "
          class="cursor-pointer group relative mx-3"
          @click="
            selectedData = selection_array[0];
            showDisable = true;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-gray-400 text-white rounded-lg"
            viewBox="0 0 24 24"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.disable") }}
          </div>
        </div>
        <div
          v-if="
            hasPrivilege('accounts_disable_accounts') &&
              !selection_array[0].enabled
          "
          class="cursor-pointer group relative mx-3"
          @click="
            selectedData = selection_array[0];
            enableData();
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-green-500 text-green-500 rounded-lg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.enable") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounts_delete_accounts')"
          @click="
            selectedData = selection_array[0];
            showDelete = true;
          "
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
      </div>
      <div
        class="flex justify-start gap-2"
        v-if="selection_array.length > 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          v-if="hasPrivilege('accounts_delete_accounts')"
          @click="showDeleteAll = true"
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.delete") }}
          </div>
        </div>
        <div
          v-if="
            hasPrivilege('system_ownership') &&
              hasPrivilege('accounts_edit_accounts')
          "
          @click="showEditExpirationAll = true"
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6 bg-yellow-500 text-white rounded-lg inline-block"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
            />
          </svg>

          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            تعديل انتهاء الكل
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounts_disconnect_accounts')"
          class="cursor-pointer group relative"
          @click="showClearAll = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-green-400 rounded-lg text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.clear") }}
          </div>
        </div>
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PillDefault from "../components/PillDefault.vue";
import AccountDetails from "../components/modals/AccountDetails.vue";
import AccountMoney from "../components/modals/AccountMoney.vue";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import PaginationRow from "../components/PaginationRow.vue";
import AccountsContext from "../components/contexts/AccountsContext.vue";
import ColumnsSelect from "../components/modals/ColumnsSelect.vue";
import TableCheckBox from "../components/formElements/TableCheckBox.vue";
import ProfileDetails from "../components/modals/ProfileDetails.vue";
import PieChart from "../components/PieChart.vue";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
import RenewProfile from "../components/modals/RenewProfile.vue";
import EditAccountsExpiration from "../components/modals/EditAccountsExpiration.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import PushAccountNotification from "../components/modals/PushAccountNotification.vue";
import TempSpeed from "../components/modals/TempSpeed.vue";
export default {
  components: {
    PillDefault,
    AccountDetails,
    DeleteConfirm,
    PaginationRow,
    AccountsContext,
    ColumnsSelect,
    TableCheckBox,
    ProfileDetails,
    PieChart,
    WarnningConfirm,
    RenewProfile,
    LoadingOverlay,
    PushAccountNotification,
    EditAccountsExpiration,
    AccountMoney,
    TempSpeed,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  name: "Account",
  data() {
    return {
      dataList: null,
      meta: {
        last_page: 0,
      },
      selectedData: { profile: null },
      showDetails: false,
      showProfileDetails: false,
      showDelete: false,
      showDeleteAll: false,
      showDisable: false,
      showEnable: false,
      showDisconnect: false,
      showClear: false,
      showContext: false,
      showRenew: false,
      showNotification: false,
      showEditExpirationAll: false,
      showMoney: false,
      showClearAll: false,
      showTempSpeed: false,
      loading: true,
      loading_overlay: false,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        save: false,
        first_name: "",
        last_name: "",
        username: "",
        phone: "",
        phone_line: "",
        address: "",
        notes: "",
        profile: "",
        ip_address: "",
        expires_at: "",
        allowed_sessions: "",
        auto_renew: "",
        remaining_quota_mb: "",
        active: "",
        online: "",
        mac_address: "",
        id: "",
        serial_number: "",
      },
      checkedBox: true,
      allSelected: false,
      showColumnsSelect: false,
      columns: {
        id: {
          name: this.$t("accounts.id"),
          enabled: false,
          sort: false,
        },
        serial_number: {
          name: this.$t("accounts.serial_number"),
          enabled: false,
          sort: false,
        },
        first_name: {
          name: this.$t("accounts.first_name"),
          enabled: false,
          sort: false,
        },
        last_name: {
          name: this.$t("accounts.last_name"),
          enabled: false,
          sort: false,
        },
        username: {
          name: this.$t("accounts.username"),
          enabled: true,
          sort: false,
        },
        password: {
          name: this.$t("accounts.password"),
          enabled: true,
          sort: false,
        },
        phone: { name: this.$t("accounts.phone"), enabled: false, sort: false },
        phone_line: {
          name: this.$t("accounts.phone_line"),
          enabled: false,
          sort: false,
        },
        address: {
          name: this.$store.getters.settings.center
            ? "رقم البوابة"
            : this.$t("accounts.address"),
          enabled: false,
          sort: false,
        },
        notes: {
          name: this.$t("accounts.notes"),
          enabled: false,
          sort: false,
        },
        profile: {
          name: this.$t("accounts.profile"),
          enabled: true,
          sort: false,
        },
        ip_address: {
          name: this.$t("accounts.ip_address"),
          enabled: false,
          sort: false,
        },
        expires_at: {
          name: this.$t("accounts.expires_at"),
          enabled: true,
          sort: false,
        },
        allowed_sessions: {
          name: this.$t("accounts.sessions"),
          enabled: false,
          sort: false,
        },
        auto_renew: {
          name: this.$t("accounts.auto_renew"),
          enabled: false,
          sort: false,
        },
        remaining_quota_mb: {
          name: this.$t("accounts.used_quota"),
          enabled: true,
          sort: false,
        },
        active: {
          name: this.$t("accounts.status"),
          enabled: true,
          sort: false,
        },
        online: {
          name: this.$t("accounts.connection"),
          enabled: true,
          sort: false,
        },
        debt: {
          name: this.$t("accounts.debts"),
          enabled: true,
          sort: false,
        },
        mac_address: {
          name: this.$t("accounts.mac_address"),
          enabled: true,
          sort: false,
        },
        last_seen: {
          name: this.$t("accounts.last_seen"),
          enabled: false,
          sort: false,
        },
        paid_at: {
          name: this.$t("accounts.paid_at"),
          enabled: false,
          sort: false,
        },
        created_at: {
          name: this.$t("control.created_at"),
          enabled: false,
          sort: false,
        },
      },
      autoUpdate: false,
      selection_array: [],
      sort_col: null,
      sort_dir: null,
      debounce: null,
      debounce_counter: 0,
      in_debt: false,
      pdfOptions: {
        margin: 5,
        image: {
          type: "jpeg",
          quality: 1,
        },
        html2canvas: { scale: 1, dpi: 96, letterRendering: true },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
        },
      },
      exportFilename: "accounts.pdf",
    };
  },
  async created() {
    this.prepareColumns();
    this.prepareFilters();
    if (this.$route.query.type) {
      switch (this.$route.query.type) {
        case "online":
          this.filters.online = "online";
          break;
        case "expired":
          this.filters.active = "expired";
          break;
        case "disabled":
          this.filters.active = "disabled";
          break;
        case "expiring":
          this.filters.active = "expiring";
          break;
        case "expiration_profile":
          this.filters.active = "expiration_profile";
          break;
        case "in_debt":
          this.in_debt = true;
      }
    }
    if (this.$route.query.useranme) {
      this.filters.username = this.$route.query.useranme;
      this.columns.enabled = true;
    }
    this.applyFilters();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async fetchAccounts() {
      if (this.filters.rows > 0 || this.filters.page > 0) {
        this.fetchDataPortion(this.filters.rows, this.filters.page);
        this.selection_array = [];
        return;
      }
      const response = await axios.get("accounts");
      this.dataList = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    prepareFilters() {
      var filters = localStorage.getItem("accounts_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    prepareColumns() {
      var cols = localStorage.getItem("accounts_columns");
      if (cols != null) {
        cols = JSON.parse(cols);
        this.columns = {
          first_name: {
            name: this.$t("accounts.first_name"),
            enabled: cols[0].enabled,
            sort: false,
          },
          last_name: {
            name: this.$t("accounts.last_name"),
            enabled: cols[1].enabled,
            sort: false,
          },
          username: {
            name: this.$t("accounts.username"),
            enabled: cols[2].enabled,
            sort: false,
          },
          password: {
            name: this.$t("accounts.password"),
            enabled: cols[3].enabled,
            sort: false,
          },
          phone: { name: this.$t("accounts.phone"), enabled: cols[4].enabled },
          phone_line: {
            name: this.$t("accounts.phone_line"),
            enabled: cols[5].enabled,
          },
          address: {
            name: this.$store.getters.settings.center
              ? "رقم البوابة"
              : this.$t("accounts.address"),
            enabled: cols[6].enabled,
          },
          notes: {
            name: this.$t("accounts.notes"),
            enabled: cols[7].enabled,
          },
          profile: {
            name: this.$t("accounts.profile"),
            enabled: cols[8].enabled,
            sort: false,
          },
          ip_address: {
            name: this.$t("accounts.ip_address"),
            enabled: cols[9].enabled,
            sort: false,
          },
          expires_at: {
            name: this.$t("accounts.expires_at"),
            enabled: cols[10].enabled,
            sort: false,
          },
          allowed_sessions: {
            name: this.$t("accounts.sessions"),
            enabled: cols[11].enabled,
            sort: false,
          },
          auto_renew: {
            name: this.$t("accounts.auto_renew"),
            enabled: cols[12].enabled,
            sort: false,
          },
          remaining_quota_mb: {
            name: this.$t("accounts.used_quota"),
            enabled: cols[13].enabled,
            sort: false,
          },
          active: {
            name: this.$t("accounts.status"),
            enabled: cols[14] != null ? cols[14].enabled : true,
            sort: false,
          },
          online: {
            name: this.$t("accounts.connection"),
            enabled: cols[15] != null ? cols[15].enabled : true,
            sort: false,
          },
          debt: {
            name: this.$t("accounts.debts"),
            enabled: cols[16] != null ? cols[16].enabled : true,
            sort: false,
          },
          mac_address: {
            name: this.$t("accounts.mac_address"),
            enabled: cols[17] != null ? cols[17].enabled : true,
            sort: false,
          },
          last_seen: {
            name: this.$t("accounts.last_seen"),
            enabled: cols[18] != null ? cols[18].enabled : false,
            sort: false,
          },
          paid_at: {
            name: this.$t("accounts.paid_at"),
            enabled: cols[19] != null ? cols[19].enabled : false,
            sort: false,
          },
          created_at: {
            name: this.$t("control.created_at"),
            enabled: cols[20] != null ? cols[20].enabled : false,
            sort: false,
          },
          id: {
            name: this.$t("accounts.id"),
            enabled: cols[21] != null ? cols[21].enabled : false,
            sort: false,
          },
          serial_number: {
            name: this.$t("accounts.serial_number"),
            enabled: cols[22] != null ? cols[22].enabled : false,
            sort: false,
          },
        };
      }
    },
    sort(param) {
      this.sort_col = param;
      if (!this.columns[param].sort) {
        this.sort_dir = "ASC";
        // this.dataList.sort((a, b) =>
        //   a[param] > b[param] ? 1 : b[param] > a[param] ? -1 : 0
        // );
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false; // the value of the current key.
        });
        this.columns[param].sort = true;
      } else {
        this.sort_dir = "DESC";
        // this.dataList.sort((a, b) =>
        //   a[param] < b[param] ? 1 : b[param] < a[param] ? -1 : 0
        // );
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false; // the value of the current key.
        });
        this.columns[param].sort = false;
      }
      this.applyFilters();
    },
    sort_profile() {
      this.sort_col = "profile";
      if (!this.columns["profile"].sort) {
        this.sort_dir = "ASC";
        this.dataList.sort((a, b) =>
          a.profile.name > b.profile.name
            ? 1
            : b.profile.name > a.profile.name
            ? -1
            : 0
        );
        this.columns["profile"].sort = true;
      } else {
        this.sort_dir = "DESC";
        this.dataList.sort((a, b) =>
          a.profile.name < b.profile.name
            ? 1
            : b.profile.name < a.profile.name
            ? -1
            : 0
        );
        this.columns["profile"].sort = false;
      }
    },
    async applyFilters() {
      this.loading = true;
      var requestString = "accounts?filters=1";
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.id != "") requestString += `&id=${this.filters.id}`;
      if (this.filters.serial_number != "")
        requestString += `&serial_number=${this.filters.serial_number}`;
      if (this.filters.first_name != "")
        requestString += `&first_name=${this.filters.first_name}`;
      if (this.filters.last_name != "")
        requestString += `&last_name=${this.filters.last_name}`;
      if (this.filters.username != "")
        requestString += `&username=${this.filters.username}`;
      if (this.filters.profile != "")
        requestString += `&profile=${this.filters.profile}`;
      if (this.filters.phone != "")
        requestString += `&phone=${this.filters.phone}`;
      if (this.filters.phone_line != "")
        requestString += `&phone_line=${this.filters.phone_line}`;
      if (this.filters.address != "" && this.filters.address)
        requestString += `&address=${this.filters.address}`;
      if (this.filters.notes != "" && this.filters.notes)
        requestString += `&notes=${this.filters.notes}`;
      if (this.filters.ip_address != "")
        requestString += `&ip_address=${this.filters.ip_address}`;
      if (this.filters.expires_at != "")
        requestString += `&expires_at=${this.filters.expires_at}`;
      if (this.filters.auto_renew != "")
        requestString += `&auto_renew=${this.filters.auto_renew}`;
      if (this.filters.allowed_sessions != "")
        requestString += `&allowed_sessions=${this.filters.allowed_sessions}`;
      if (this.filters.active != "")
        requestString += `&active=${this.filters.active}`;
      if (this.filters.online != "")
        requestString += `&online=${this.filters.online}`;
      if (this.filters.created_at != "" && this.filters.created_at != undefined)
        requestString += `&created_at=${this.filters.created_at}`;
      if (this.filters.paid_at != "" && this.filters.paid_at != undefined)
        requestString += `&paid_at=${this.filters.paid_at}`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      if (this.in_debt) requestString += `&in_debt=true`;
      if (this.debounce != null) {
        this.debounce_counter++;
      } else {
        this.debounce_counter--;
      }
      var local_debounce_location = this.debounce_counter;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        if (local_debounce_location == this.debounce_counter) {
          this.dataList = Object.values(response.data.data);
          if (this.filters.save)
            localStorage.setItem(
              "accounts_filters",
              JSON.stringify(this.filters)
            );
          if (this.dataList.length < 1 && this.filters.page != 1) {
            this.filters.page = 1;
            this.applyFilters();
          }
          if (this.sort_col) {
            if (this.sort_dir == "ASC") {
              this.dataList.sort((a, b) =>
                a[this.sort_col] > b[this.sort_col]
                  ? 1
                  : b[this.sort_col] > a[this.sort_col]
                  ? -1
                  : 0
              );
            } else {
              this.dataList.sort((a, b) =>
                a[this.sort_col] < b[this.sort_col]
                  ? 1
                  : b[this.sort_col] < a[this.sort_col]
                  ? -1
                  : 0
              );
            }
          }
          if (this.sort_col == "profile") {
            this.sort_profile();
          }
          this.meta = response.data.meta;
          this.loading = false;
        }
      }, 1000);
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    async editData(account, tab = "same") {
      if (tab == "same") this.$router.push(`/accounts/${account.id}/edit`);
      else this.openInNewTab(`/accounts/${account.id}/edit`);
    },
    async renewTemp(account) {
      this.loading = true;
      const response = await axios.post(`accounts/${account.id}/renew/temp`);
      if (response.status == 200) {
        this.applyFilters();
        this.loading = false;
        this.selection_array = [];
        this.$router.push({ query: { success: "success" } });
      }
    },
    async speedTemp(account) {
      this.selectedData = account;
      this.showTempSpeed = true;
    },
    openInNewTab(url) {
      window.open(url, "_blank").focus();
    },
    async editProfile(account) {
      this.$router.push(`/profiles/${account.profile.id}/edit`);
      this.$store.commit("changeKey");
    },
    async renewData(account) {
      this.selectedData = account;
      this.showRenew = true;
      // this.$router.push(`/accounts/${account.id}/renew`);
    },
    async clearData() {
      const response = await axios.put(
        `/accounts/${this.selectedData.id}/clear`
      );
      if (response.status == 200) {
        this.applyFilters();
        this.showClear = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    async deleteData() {
      this.showDelete = false;
      this.loading_overlay = true;
      const response = await axios.delete(`accounts/${this.selectedData.id}`);
      if (response.status == 200) {
        this.applyFilters();
        this.$router.push({ query: { success: "success" } });
      }
      this.loading_overlay = false;
    },
    async disableData() {
      const response = await axios.put(
        `accounts/${this.selectedData.id}/disable`
      );
      if (response.status == 200) {
        this.applyFilters();
        this.showDisable = false;
        this.showEnable = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    async enableData() {
      const response = await axios.put(
        `accounts/${this.selectedData.id}/disable`
      );
      if (response.status == 200) {
        this.applyFilters();
        this.$router.push({ query: { success: "success" } });
      }
    },
    async disconnectData() {
      const response = await axios.put(
        `accounts/${this.selectedData.id}/disconnect`
      );
      if (response.status == 200) {
        this.applyFilters();
        this.showDisconnect = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    async groupDelete() {
      this.showDeleteAll = false;
      this.loading_overlay = true;
      const response = await axios.post(`accounts/group`, {
        accounts: this.selection_array,
      });
      if (response.status == 200) {
        this.selection_array = [];
        this.applyFilters();
        this.$router.push({ query: { success: "success" } });
      }
      this.loading_overlay = false;
    },
    async groupClear() {
      this.loading_overlay = true;
      const response = await axios.post(`accounts/group/clear`, {
        accounts: this.selection_array,
      });
      if (response.status == 200) {
        this.selection_array = [];
        this.showClearAll = false;
        this.applyFilters();
        this.$router.push({ query: { success: "success" } });
      }
      this.loading_overlay = false;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatDateHours(date) {
      //todo fix UTC BUG
      if (date == "never" || date == "" || date == null) return "N/A";
      return moment(date)
        .add(3, "hours")
        .format("DD-MM-YYYY hh:mm");
    },
    openContextMenu(e, data) {
      this.selectedData = data;
      this.$refs.menu.open(e);
    },
    checkAll() {
      if (this.allSelected) {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = false;
        });
        this.allSelected = false;
      } else {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = true;
          this.selection_array.push(element);
        });
        this.allSelected = true;
      }
    },
    selectData(data) {
      if (data.selected == false) {
        data.selected = true;
        this.selection_array.push(data);
      } else {
        data.selected = false;
        this.selection_array.splice(this.selection_array.indexOf(data), 1);
      }
    },
    updateColumns(updated) {
      this.columns = updated;
      this.showColumnsSelect = false;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    percentColor(card) {
      var percent = parseFloat(
        (card.used_quota_mb * 100) / card.allowed_quota_mb
      ).toFixed(0);
      if (percent > 0 && percent <= 49) return "#009BFF";
      else if (percent > 49 && percent <= 74) return "#fcba35";
      else if (percent > 74 && percent < 100) return "#ff2626";
      else return "#000000";
    },
    exportCsv() {
      window.location.href =
        localStorage.getItem("server_api") + `accounts/export`;
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem("accounts_filters", JSON.stringify(this.filters));
    },
  },
};
</script>
<style scoped>
@media screen {
  .noPrint {
  }
  .noScreen {
    display: none;
  }
  .scroll {
    height: 30rem;
  }
}

@media print {
  .noPrint {
    display: none;
  }
  .noScreen {
  }
  .scroll {
  }
}
</style>
